<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="7"
                lg="10"
                md="10"
                sm="6"
            >
                <h3>Cotações / Pedidos</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                style="display: flex; align; align-content: center; align-items: center;"
                cols="12"
                lg="2"
                md="2"
                sm="12"
            >
                <v-radio-group 
                    row 
                    v-model="tipoRelatorio"
                    style="width: 100%"
                    @change="getRegisters()"
                >
                    <v-radio label="Cotação" :value="1" color="var(--color__main)"></v-radio>
                    <v-radio label="Pedido"  :value="2" color="var(--color__main)"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col 
                cols="12"
                :lg="isUsuarioInterno ? 4 : 8"
                :md="isUsuarioInterno ? 4 : 8"
                sm="12"
            >
                <label>Usuário</label>
                <v-combobox
                    v-model="usuarioSelected"
                    :items="listUsuarios"
                    item-text="nome"
                    item-value="id"
                    clearable
                    outlined
                    @change="getRegisters()"
                ></v-combobox>
            </v-col>
            <v-col
                v-if="isUsuarioInterno == true"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
                <label>Distribuidor</label>
                <v-combobox
                    v-model="distribuidorSelected"
                    :items="listDistribuidores"
                    item-text="nameAndCnpj"
                    item-value="id"
                    clearable
                    outlined
                    @change="getRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                style="display: flex; align; align-content: center; align-items: center;"
                cols="12"
                lg="2"
                md="2"
                sm="12"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <div class="exportButtons">
                            <v-btn 
                                style="width: 45px; border: none !important;"
                                class="btnActions"
                                outlined
                                v-on="on"
                                color="var(--color__main) !important"
                                small
                                @click="exportar()"
                            >
                                <v-icon v-on="on" large>mdi mdi-file-excel-outline</v-icon>
                            </v-btn>
                            <label style="font-size: 12px;">Exportar</label>
                        </div>
                    </template>
                    <span>Exportar para Excel</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :options.sync="options"
                    :loading="loading"
                    :items="listRelatorio"
                    :items-per-page="30"
                    :no-data-text="$t('nenhumRegistroEncontrado')"
                    :footer-props="{
                        'items-per-page-text':$t('registrosPorPagina'),
                        'items-per-page-all-text':$t('todos'),
                        pageText: '{0}-{1} de {2}'
                    }"
                >
                </v-data-table>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';

    export default ({

        mixins: [Helpers],

        data: () => ({

            loading: false,

            headers: [],

            options: { rowsPerPage: 30, page: 1 },
            
            tipoRelatorio: 1,
            usuarioSelected: null,
            distribuidorSelected: null,
            listUsuarios: [],
            listDistribuidores: [],
            listRelatorio: [],
        }),
        
        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            isUsuarioInterno() {
                return this.userLoggedGetters.usuarioInterno == 1;
            }
        },

        methods: {

            async getLists() {
                let listUsuarios = await this.$store.dispatch("usuarioModule/List");
                let listDistribuidores = await this.$store.dispatch("empresaModule/ListDistribuidor");

                if (this.isUsuarioInterno == false) {

                    const idDistribuidor = this.userLoggedGetters.distribuidorSelected.id;

                    listUsuarios = listUsuarios.filter(usu => usu.idDistribuidor == idDistribuidor);

                    listDistribuidores = listDistribuidores.filter(dis => dis.id == idDistribuidor);

                    if (listDistribuidores != null && listDistribuidores != undefined) {
                        this.distribuidorSelected = listDistribuidores[0];
                    }
                }

                this.listDistribuidores = listDistribuidores;
                this.listUsuarios = listUsuarios;
            },

            async getRequest() {

                this.headers = [
                    { text: "Cotação/Pedido", value: "idCotacaoPedido", sortable: true },
                    { text: this.$t('criadoEm'), value: "dataCadastroFormatted", sortable: true },
                    { text: this.$t('criadoPor'), value: "nomeUsuarioCadastro", sortable: true },
                    { text: this.$t('marca'), value: "marcaDescricao", sortable: true },
                    { text: this.$t('tipoCliente'), value: "tipoClienteDescricao", sortable: true },
                    { text: this.$t('cliente'), value: "nomeCliente", sortable: true },
                    { text: "Total Líquido", value: "valorTotalLiquidoFormatted", sortable: false, align: "right" },
                    { text: "Produto", value: "codigoProduto", sortable: true, align: "left" },
                    { text: "Qtde", value: "qtde", sortable: false, align: "center" },
                    { text: "Total Item", value: "valorTotalItemFormatted", sortable: false, align: "right" },
                    { text: this.$t('status'), value: `${this.tipoRelatorio == 1 ? 'statusCotacaoDescricao' : this.isUsuarioInterno == false ? 'descricaoStatusCliente' : 'statusPedidoDescricao'}`, sortable: true }
                ]

                if (this.isUsuarioInterno == true) {
                    this.headers.splice(9, 0, { text: "Possui Desc?", value: "possuiDesconto", sortable: false, align: "center" });
                    this.headers.splice(10, 0, { text: "Desc. Manual", value: "descontoUsuarioFormatted", sortable: false, align: "center" });
                }

                let cotacaoPedidoRequest = {
                    tipoRelatorio: this.tipoRelatorio,
                    idUsuario: 0,
                    idDistribuidor: "0",
                }

                if (this.usuarioSelected != null && this.usuarioSelected != undefined) {
                    cotacaoPedidoRequest.idUsuario = this.usuarioSelected.id;
                }

                if (this.distribuidorSelected != null && this.distribuidorSelected != undefined) {
                    cotacaoPedidoRequest.idDistribuidor = this.distribuidorSelected.id;
                }

                return cotacaoPedidoRequest;
            },

            async getRegisters() {
                const request = await this.getRequest();

                this.listRelatorio = await this.$store.dispatch("relatoriosModule/ListCotacaoPedido", request);
            },

            async exportar() {
                
                this.showLoading();

                const request = await this.getRequest();

                this.$spTechApi.post(`relatorio/cotacao-pedido-exportar`, request, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });

                        var fileName = 'CotacaoPedido.xlsx';

                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");

                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);

                        this.hideLoading();
                    })
                    .catch(error => {
                        
                        let errorMessage = "";
                        if (error.response && error.response.status === 400) {
                            errorMessage = `Erro ao exportar: [${error.response.data}]`;
                        }
                        else {
                            errorMessage = `Erro ao exportar: [${error}]`;
                        }
                        
                        this.showToast("error", "Erro!", errorMessage);
                        this.hideLoading();
                    }
                );
            },
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .exportButtons {
        display: flex; 
        flex-direction: column; 
        align-items: center;
    }
</style>